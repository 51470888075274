import * as React from "react";
import { useEffect, useState } from "react";
import { useAPI } from "../api/API";
import { useParams } from "react-router-dom";

const Results = () => {
    const api = useAPI();
    const params = useParams();

    const [result, setResult] = useState([]);
    const [order, setOrder] = useState("scenario");
    const type = params.type ?? "team";

    useEffect(() => {
        const download = async () => {
            const leaderboard = await api.getLeaderboard({
                type,
                order_by: [order],
            });
            setResult(leaderboard);
        };

        download();
    }, [api, order, type]);

    function order_by(name, is_two_way) {
        const negName = "-" + name;
        return function () {
            if (order === name) {
                if (is_two_way) {
                    setOrder(negName);
                }
            } else if (order === negName) {
                setOrder(name);
            } else {
                if (is_two_way) {
                    setOrder(negName);
                } else {
                    setOrder(name);
                }
            }
        };
    }

    function order_class(name) {
        const negName = "-" + name;
        if (order === name) {
            return "ordered";
        }
        if (order === negName) {
            return "ordered2";
        }
        return "";
    }

    return (
        <>
            <div className="background"></div>
            <div
                className={
                    "dash-container " +
                    (result.length > 0 ? "is-justify-content-flex-start" : "")
                }
            >
                {result.length > 0 ? (
                    <>
                        <div className="table-title">
                            <span className="t-title">ТАБЛИЦА РЕЗУЛЬТАТОВ</span>
                        </div>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>
                                            <button
                                                className={
                                                    "sort-button " +
                                                    order_class("user_name")
                                                }
                                                onClick={order_by(
                                                    "user_name",
                                                    true
                                                )}
                                            >
                                                ПОЛЬЗОВАТЕЛЬ
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                className={
                                                    "sort-button " +
                                                    order_class("command_name")
                                                }
                                                onClick={order_by(
                                                    "command_name",
                                                    true
                                                )}
                                            >
                                                КОМАНДА
                                            </button>
                                        </th>
                                        {type !== "individual" && (
                                            <th>
                                                <button
                                                    className={
                                                        "sort-button " +
                                                        order_class("room_id")
                                                    }
                                                    onClick={order_by(
                                                        "room_id",
                                                        true
                                                    )}
                                                >
                                                    КОМНАТА
                                                </button>
                                            </th>
                                        )}
                                        <th>
                                            <button
                                                className={
                                                    "sort-button " +
                                                    order_class("scenario")
                                                }
                                                onClick={order_by(
                                                    "scenario",
                                                    true
                                                )}
                                            >
                                                СЦЕНАРИЙ
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                className={
                                                    "sort-button " +
                                                    order_class("false_start")
                                                }
                                                onClick={order_by(
                                                    "false_start",
                                                    true
                                                )}
                                            >
                                                ФАЛЬСТАРТ
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                className={
                                                    "sort-button " +
                                                    order_class("flight_time")
                                                }
                                                onClick={order_by(
                                                    "flight_time",
                                                    true
                                                )}
                                            >
                                                ВРЕМЯ
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result.map((o, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{o.user_name}</td>
                                            <td>{o.command_name}</td>
                                            <td>{o.room_id}</td>
                                            <td>{o.scenario}</td>
                                            <td>
                                                {o.false_start ? "Да" : "Нет"}
                                            </td>
                                            <td>{o.flight_time}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <h1>У вас нет доступных соревнований</h1>
                )}
            </div>
        </>
    );
};

export default Results;
