import { Outlet, BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Results from "./components/Results";

import Dashboard from "./components/Dashboard";
import LoginRedirector from "./api/LoginRedirector";
import API from "./api/API";

function App() {
    return (
        <API>
            <BrowserRouter>
                <Routes>
                    <Route element={<LoginRedirector />}>
                        <Route exact path="/" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/" element={<LayoutsWithNavbar />}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/results/:type" element={<Results />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </API>
    );
}

function LayoutsWithNavbar() {
    return (
        <>
            <div className="App">
                <Navbar />
                <Outlet />
            </div>
        </>
    );
}

export default App;
