import React from "react";
import { useAPI } from "../api/API";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const Navbar = () => {
    const api = useAPI();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const resultsType = params.type ?? "team";

    const logout = async (e) => {
        e.preventDefault();
        await api.logout();
    };

    return (
        <nav className="navbar">
            <div
                className="navbar-brand is-clickable"
                onClick={() => navigate("/dashboard")}
            >
                <img alt="" src="/images/Logo-header.svg" />
                <img alt="Квадросим" src="/images/logo-text.png" />
            </div>
            <div className="navbar-links">
                {location.pathname.startsWith("/results") ? (
                    <>
                        <Link to="/dashboard">Назад</Link>
                        {resultsType === "team" ? (
                            <Link to="/results/individual">
                                Личные результаты
                            </Link>
                        ) : (
                            <Link to="/results/team">Командные результаты</Link>
                        )}
                    </>
                ) : (
                    <Link to="/results/team">Результаты соревнований</Link>
                )}
                <a className="has-text-danger" href="/" onClick={logout}>
                    Выйти
                </a>
            </div>
        </nav>
    );
};

export default Navbar;
